import axios from 'axios'
import {
  MessageBox,
  Message
} from 'element-ui'
import store from '@/store'
import router from '@/router'
//import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})
let flag = true
// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    let token = sessionStorage.getItem('token')
    if (token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = token
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    if (flag) {
      if (res.code != '1000' && res.code != undefined) {
        Message({
          message: res.subMsg || '当前访问人数太多请稍后',
          type: 'error',
          duration: 5 * 1000
        })
        if(res.code === '50031'){
          console.log('50031error,案例没有权限')
        }
        if (res.code === '7002') {

        flag = false

          MessageBox.confirm('您的账号在其他地方登陆,请重新登陆', '提示', {
            confirmButtonText: '重新登录',
            showCancelButton: false,
            type: 'warning'
          }).then(() => {
            sessionStorage.clear()
            store.state.myInfo = {}
            store.state.level = -1
            store.state.name = ''
            store.state.token = ''
            router.push({
              path: '/login'
            })
            flag = true
          }).catch(() => {
            sessionStorage.clear()
            store.state.myInfo = {}
            store.state.level = -1
            store.state.name = ''
            store.state.token = ''
            window.location.reload()
          })
        }
        return Promise.reject(new Error(res.message || 'testError'))
      } else if (res.code == undefined) {
        return res
      } else {
        return res
      }
    }

  },
  error => {
    console.log('err:' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service