<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import { Home } from '@/api/home.js';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
export default {
  name: 'APP',
  data() {
    return {

    }
  },
  mounted() {
    this.getvisitorId()

  },
  methods:{
    async getvisitorId() {
      let  fp = await FingerprintJS.load()
      let  result = await fp.get()
      let  visitorId = result.visitorId
      console.log('唯一标识', visitorId)
      Home.Uv(visitorId , 'pc').then(res => {
        console.log(' UV埋点成功:', res)
      })
    },
  }


} 
</script>
<style lang="scss">
@import './assets/scss/reset.scss';
@import './assets/scss/config.scss';
@import './assets/scss/base.scss';
</style>
