import request from '@/utils/request'

export class Home {

    /**
     * 网站UV埋点
     * @returns 
     */
    static async Uv(mac,terminal) {
        return request({
            url: '/authority/web/statistics/uv/info/public/add',
            method: 'get',
            params: {
                mac,
                terminal
            }
        })
    }



    /**
     * 获取首页数据
     * @returns 
     */
    static async homePage() {
        return request({
            url: 'content/web/home/page/public/get',
            method: 'get',
            params: {}
        })

    }

    /** 
     * 
        获取分类列表
    */
    static async classifyList(type, classifyName, pageNo, pageSize) {
        return request({
            url: 'content/web/classify/public/list',
            method: 'get',
            params: {
                type,
                classifyName,
                pageNo,
                pageSize
            }
        })
    }

    /**
     * 根据id 获取产品信息
     * @param {*} priceId 
     * @returns 
     */
    static async priceGet(priceId) {
        return request({
            url: 'content/web/product/price/public/get',
            method: 'get',
            params: {
                priceId
            }
        })
    }

    /**
     * 根据价格ID跳转材料介绍获取详情
     * @param {*} classifyId 
     * @returns 
     */
    static async getPriceDetail(classifyId) {
        return request({
            url: 'content/web/product/price/public/classify/get',
            method: 'get',
            params: {
                classifyId
            }
        })
    }
    /**
     * 获取产品参考价格文章
     * @param {*} params 
     * 
     * @returns 
     */
    static async pricePage(params) {
        return request({
            url: "content/web/product/price/public/page",
            method: 'post',
            data: params
        })
    }
    /**获取excel行数据
     * "pageNo": 0,
	"pageSize": 0,
	"pitIds": [],
	"priceId": 0
     * @param {*} params 
     * @returns 
     */
    static async excelDataPage(params){
        return request({
            url:'content/web/calculation/excel/data/page',
            method:'post',
            data:params
        })
    }

    /**
     * 获取excel 的表头
     * @param {*} priceId 
     * @returns 
     */
    static async calculationGet(priceId){
        return request({
            url:'content/web/calculation/get',
            method:'get',
            params:{
                priceId
            }
        })
    }

    /**
     * 获取可以搜索的列表
     * @param {*} priceId 
     * @returns 
     */
    static async calculationSearchGet(priceId){
        return request({
            url:"content/web/calculation/search/get",
            method:'get',
            params:{
                priceId
            }
        })
    }


    /**
     * 
     * @param {*} params 获取洁净常识文章
     * @returns 
     */
    static async cleanCommonPage(params) {
        return request({
            url: "content/web/clean/common/public/page",
            method: 'post',
            data: params
        })
    }
    /**
     * 获取详情
     * @param {*} senseId 
     * @returns 
     */
    static async cleanCommonDetail(senseId) {
        return request({
            url: '/content/web/clean/common/public/get',
            method: 'get',
            params: {
                senseId
            }
        })
    }

    /**
     * 根据id 洁净规范
     * @param {*} specificationId 
     * @returns 
     */
    static async cleanSpec(specificationId) {
        return request({
            url: "content/web/clean/specification/public/get",
            method: 'get',
            params: {
                specificationId
            }
        })
    }

    /**
     * 洁净规范价格文章
     * @param {*} params 
     * @returns 
     */
    static async cleanSpecPage(params) {
        return request({
            url: 'content/web/clean/specification/public/page',
            method: 'post',
            data: params
        })
    }
    /**
     * 获取详情
     * @param {*} specificationId 
     * @returns 
     */
    static async cleanSpecDetail(specificationId) {
        return request({
            url: '/content/web/clean/specification/public/get',
            method: 'get',
            params: {
                specificationId
            }
        })
    }

    /**
     * 根据id 下载工程案例
     * @param {*} params 
     * @returns 
     */
    static async productCaseDown(params) {
        return request({
            url: 'content/web/product/case/download',
            method: 'post',
            data: params
        })
    }

    /**
     * 据id 获取产品案例信息
     * @param {*} caseId 
     * @returns 
     */
    static async productCaseGet(caseId) {
        return request({
            url: "content/web/product/case/public/get",
            method: "get",
            params: {
                caseId
            }
        })
    }

    /**
     * 获取产品案例文章
     * @param {*} params 
     * @returns 
     */
    static async productCasePage(params) {
        return request({
            url: 'content/web/product/case/public/page',
            method: 'post',
            data: params
        })
    }
    /**
     * 获取用户详情
     * @returns 
     */
    static async userDetail() {
        return request({
            url: "system/web/user/get/user/detail",
            method: 'get',
            params: {}
        })
    }

    // /**
    //  * 设置/修改 收货地址
    //  */
    // static async setAddr (params){
    //     return request({
    //         url:'system/web/user/set/address',
    //         method:'get',
    //         data:params
    //     })
    // }


    /**
     * 手机号+验证码 登录
     * @param {*} params 
     * @returns 
     */
    static async userLogin(params) {
        return request({
            url: 'system/web/user/login',
            method: 'post',
            data: params
        })
    }


    /**
     * 用户端账号密码登陆
     * "pwd": "string",
  "userName": "string"
     * @param {*} params 
     * @returns 
     */
    static async pwdLogin(params) {
        return request({
            url: 'system/web/user/pwd/login',
            method: 'post',
            data: params
        })
    }

    /**
     * 用户修改密码
     * @param {*} params 
     * "code": "string",
  "confirmPassWord": "string",
  "newPassword": "string",
  "telephone": "string",
  "userId": 0
     * @returns 
     */
    static async pwdUpdate(params) {
        return request({
            url: 'system/web/user/public/pwd/update',
            method: 'post',
            data: params
        })
    }


    /**
     * 生成图形验证码
     * @returns 
     */
    static async kaptchaGet(kaptcha) {
        return request({
            url: 'system/web/user/public/kaptcha/get',
            method: 'get',
            params: {
                kaptcha
            }
        })
    }


    /**
     * 手机号+ 验证码 注册
     * @param {*} params 
     * @returns 
     */
    static async register(params) {
        return request({
            url: "system/web/user/public/register",
            method: 'post',
            data: params
        })
    }

    /**
     * 发送验证码
     * @param {*} params 
     * @returns 
     */
    static async sendSms(params) {
        return request({
            url: "system/web/user/public/send/sms",
            method: 'post',
            data: params
        })
    }

    /**
     * 收货地址
     * @param {*} params 
     * @returns 
     */
    static async setAddress(params) {
        return request({
            url: "system/web/user/set/address",
            method: 'post',
            data: params
        })
    }
    /**
     * 设置邮箱地址
     * @param {*} params 
     * @returns 
     */
    static async setEmail(params) {
        return request({
            url: '/system/web/user/set/email',
            method: 'post',
            data: params
        })
    }


    /**
     * 修改用户信息
     * @param {*} params 
     * @returns 
     */
    static async userUpdate(params) {
        return request({
            url: 'system/web/user/update',
            method: 'post',
            data: params
        })
    }

 

    /**
     * 
     * @param {*} keyWord 
     * @returns 
     */
    static async GetAll(keyWord) {
        return request({
            url: 'elasticsearch/web/es/content/public/get/all',
            method: 'get',
            params: {
                keyWord
            }
        })
    }

    /**设置的默认地址类别: 0 实际地址 1 邮箱地址
     * @param {Object} pageNo  
     * @param {Object} pageSize
     * @param {Object} userAddressType 0 实际地址 1 邮箱地址
     */

    static async pageAddress(pageNo, pageSize, userAddressType) {
        return await request({
            url: '/system/web/user/page/address',
            method: 'get',
            params: {
                pageNo,
                pageSize,
                userAddressType
            }
        })
    }

    /**
    		删除用户地址
    	 * @param {Object} addressId
    	 */
    static async removeAddress(addressId) {
        return await request({
            url: '/system/web/user/remove/address',
            method: 'get',
            params: {
                addressId
            }
        })
    }

    /********支付相关******************** */
    static async alipay(params) {
        return request({
            url: '/aliyun/alipay/pay',
            method: 'post',
            data: params
        })
    }

    /**************订单 *************/
    static async getOrders(pageNo, pageSize) {
        return request({
            url: '/aliyun/web/pay/get/order',
            method: 'get',
            params: {
                pageNo,
                pageSize
            }
        })
    }

    /**
     * 填写留言
     * @param {*} params 
     * @returns 
     */
    static async formAdd(params) {
        return request({
            url: 'content/web/leave/message/public/save',
            method: 'post',
            data: params
        })
    }

    /**
     * 获取更多动态
     * @param {*} params 
     * @returns 
     */
    static async moreNews(params) {
        return request({
            url: '/content/web/home/page/public/hotnews/list',
            method: 'post',
            data: params
        })
    }

    /**
     * 最新动态获取详情
     * @param {*} hotNewsId 
     * @returns 
     */
    static async hotNewsDetail(hotNewsId) {
        return request({
            url: "/content/web/home/page/public/hotnews/get",
            method: 'get',
            params: {
                hotNewsId
            }
        })
    }

    /**
     * 提供服务获取详情
     * @param {*} provideId 
     * @returns 
     */
    static async provideDetail(provideId) {
        return request({
            url: "/content/web/home/page/public/provide/get",
            method: 'get',
            params: {
                provideId
            }
        })
    }

    /**
     * 材料介绍搜索
     * @param {*} type 
     * @param {*} classifyName 
     * @returns 
     */
    //content/web/classify/public/list/all?type=1&classifyName=彩
    static async classifyPublicList(type, classifyName) {
        return request({
            url: 'content/web/classify/public/list/all',
            method: 'get',
            params: {
                type,
                classifyName
            }
        })
    }

    /**
     * 微信扫码登陆
     * @param {*} params 
     * appId
     * code
     * @returns 
     */
    static async wxScanLogin(params) {
        return request({
            url: 'system/web/weixin/user/login',
            method: 'post',
            data: params
        })
    }

    /**
     * @param {Object} params 用户查看留言墙
     * "collection": 0, 是否按照收藏数排序:0 不排序 1排序 默认排序是top和时间倒序
     * "endTime": "2022-11-02T06:05:02.038Z",
     * "isAdmin": true,
     * "isMine": true, 是否查看我的留言
     * "keyWord": "string",	string 留言内容
     * "pageNo": 0,
     * "pageSize": 0,
     * "startTime": "2022-11-02T06:05:02.038Z",
     * "thumbUp": 0, 是否按照点赞数排序:0 不排序 1排序 默认排序是top和时间倒序
     * "timeInterval": 0, 近三天:3 近一周:7 近一个月:30, 单位:天
     * "userId": 0
     */
    static async publicPage(params) {
        return await request({
            url: "/content/web/leave/message/public/page",
            method: 'post',
            data: params
        })
    }


    /**************购物车*********/
    /**
     * 获取购物车列表
     */
    static async cartList() {
        return await request({
            url: "/aliyun/web/shopping/cart/list",
            method: 'get',
            params: {}
        })
    }

    /**购物车修改商品数量
     * @param {Object} goodsId
     * @param {Object} goodsNumB
     * @param {Object} num
     */
    static async cartModifyNum(goodsId, goodsNumB, num) {
        return await request({
            url: "/aliyun/web/shopping/cart/modify/num",
            method: 'get',
            params: {
                goodsId,
                goodsNumB,
                num
            }
        })
    }

    /**
		购物车删除商品
	  * @param {Object} goodsId
	  */
    static async cartRemove(goodsId) {
        return await request({
            url: "/aliyun/web/shopping/cart/remove",
            method: 'get',
            params: {
                goodsId
            }
        })
    }


    /**加入购物车
     * @param {Object} goodsId
     * goodsType
     * 1会员价格，2 项目案例 3 论坛 4 材料介绍 5 案例下载
     */
    static async cartSet(goodsId, goodsType) {
        return await request({
            url: '/aliyun/web/shopping/cart/set',
            method: 'get',
            params: {
                goodsId,
                goodsType
            }
        })
    }
    /**************购物车结束*********/

}