
import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  {
    path: '/',
    name: 'index',
    component:resolve => require(['/src/views/index'],resolve),
    meta:{title:'首页',keepAlive: true}
  },
  {
    path: '/login',
    name: 'login',
    component:resolve => require(['/src/views/Login/index'],resolve),
    meta:{title:'用户登陆'}
  },
  {
    path: '/wxLogin',
    name: 'wxLogin',
    component:resolve => require(['/src/views/Login/wxLogin'],resolve),
    meta:{title:'扫码登陆'}
  },
  {
    path: '/sign',
    name: 'sign',
    component:resolve => require(['/src/views/Login/sign'],resolve),
    meta:{title:'用户登陆'}
  },
  {
    path: '/resetPwd',
    name: 'resetPwd',
    component:resolve => require(['/src/views/Login/resetPwd'],resolve),
    meta:{title:'密码重置'}
  },
  {
    path: '/xieyi',
    name: 'xieyi',
    component:resolve => require(['/src/views/xieyi'],resolve),
    meta:{title:'用户协议'}
  },
  
  {
    path:'',
    name:'commonSense',
    component:resolve => require(['/src/views/home'],resolve),
    children:[
      {
        path:'/commonSense',
        name:'commonSense',
        component:resolve=>require(['/src/views/Commonsense/index'],resolve),
        meta:{title:'洁净常识'}
      },
      {
        path:'/standard',
        name:'standard',
        component:resolve=>require(['/src/views/Standard/index'],resolve),
        meta:{title:'洁净规范'}
      },
      {
        path:'/price',
        name:'price',
        component:resolve=>require(['/src/views/Price/index'],resolve),
        meta:{title:'价格参考'}
      },
      {
        path:'/priceDetail',
        name:'priceDetail',
        component:resolve=>require(['/src/views/Price/detail'],resolve),
        meta:{title:'价格参考详情'}
      },
      {
        path:'/example',
        name:'example',
        component:resolve=>require(['/src/views/Example/index'],resolve),
        meta:{title:'项目案例'}
      },
      {
        path:'/materials',
        name:'example',
        component:resolve=>require(['/src/views/Materials/index'],resolve),
        meta:{title:'材料介绍'}
      },
      {
        path:'/materialDetail',
        name:'materialDetail',
        component:resolve=>require(['/src/views/Materials/detail'],resolve),
        meta:{title:'材料介绍'}
      },
      {
        path:'/vip',
        name:'vip',
        component:resolve=>require(['/src/views/Vip/index'],resolve),
        meta:{title:'会员服务'}
      },
      {
        path:'/detail',
        name:'detail',
        component:resolve=>require(['/src/views/detail'],resolve),
        meta:{title:'详情'}
      },
      {
        path:'/exampleDetail',
        name:'exampleDetail',
        component:resolve=>require(['/src/views/Example/detail'],resolve),
        meta:{title:'案例详情'}
      },
      {
        path:'/form',
        name:'Form',
        component:resolve=>require(['/src/views/form'],resolve),
        meta:{title:'留言'}
      },
      {
        path:'/search',
        name:'Search',
        component:resolve=>require(['/src/views/search'],resolve),
        meta:{title:'全局搜索'}
      },
      {
        path:'/myinfo',
        name:'myInfo',
        component:resolve=>require(['/src/views/My/myInfo'],resolve),
        meta:{title:'基本信息'}
      },
      {
        path:'/myorder',
        name:'myOrder',
        component:resolve=>require(['/src/views/My/myOrder'],resolve),
        meta:{title:'订单中心'}
      },
      {
        path:'/orderDetail',
        name:'orderDetail',
        component:resolve=>require(['/src/views/My/orderDetail'],resolve),
        meta:{title:'订单详情'}
      },
      {
        path:'/mymsg',
        name:'myMsg',
        component:resolve=>require(['/src/views/My/myMsg'],resolve),
        meta:{title:'消息中心'}
      },
      {
        path:'/myAddress',
        name:'myAddress',
        component:resolve=>require(['/src/views/My/myAddress'],resolve),
        meta:{title:'我的地址'}
      },
      {
        path:'/myCart',
        name:'myCart',
        component:resolve=>require(['/src/views/My/myCart'],resolve),
        meta:{title:'购物车'}
      },
      {
        path:'/myZan',
        name:'myZan',
        component:resolve=>require(['/src/views/My/myZan'],resolve),
        meta:{title:'点赞收藏'}
      },
      {
        path:'/searchList',
        name:'searchList',
        component:resolve=>require(['/src/views/searchList'],resolve),
        meta:{title:'搜索列表'}
      },
      {
        path:'/newsList',
        name:'newsList',
        component:resolve=>require(['/src/views/newsList'],resolve),
        meta:{title:'更多动态'}
      },
      {
        path:'/luntan',
        name:'luntan',
        component:resolve=>require(['/src/views/luntan/index'],resolve),
        meta:{title:'问答论坛'}
      },
      {
        path:'/ltdetail',
        name:'ltDetail',
        component:resolve=>require(['/src/views/luntan/detail'],resolve),
        meta:{title:'论坛详情'}
      },
      {
        path:'/cashierDesk',
        name:'cashierDesk',
        component:resolve=>require(['/src/views/Pay/index'],resolve),
        meta:{title:'收银台'}
      },
      {
        path:'/yinlianPay',
        name:'yinlianPay',
        component:resolve=>require(['/src/views/Pay/yinlian'],resolve),
        meta:{title:'立即支付'}
      },
      {
        path:'/wechatPay',
        name:'wechatPay',
        component:resolve=>require(['/src/views/Pay/detail'],resolve),
        meta:{title:'立即支付'}
      },
      {
        path:'/paySuccess',
        name:'paySuccess',
        component:resolve=>require(['/src/views/Pay/success'],resolve),
        meta:{title:'支付成功'}
      },
      {
        path:'/vipDetail',
        name:'vipDetail',
        component:resolve=>require(['/src/views/Vip/detail'],resolve),
        meta:{title:'会员详情'}
      },
      {
        path:'/master',
        name:'master',
        component:resolve=>require(['/src/views/My/master'],resolve),
        meta:{title:'分销达人'}
      },
      
    ]
  },
 
]

const router = new VueRouter({
  routes,
 // mode:'history'
})

export default router
